import React from 'react';
import style from './swirl.mod.scss';

const Swirl = ({
  toColor = style.toColor,
  midColor = style.midColor,
  midColor2 = style.midColor2,
  type = 'default',
  desktopOnly = false
}) => {
  const modifiers = [style['swirl--' + type.toLowerCase().replace(/ /gi, '-')]];

  desktopOnly && modifiers.push(style['swirl--desktop-only']);

  /**
   * Good for page top and color section start
   */
  const SwirlToWhite = () => (
    <svg
      className={style.swirl__svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1600 130"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={toColor}
          d="M1600 130H0V39.6L261 9l310.5 9.6 302.8 44.8 345.5 31.8L1600 52.9z"
        />
        <path
          fill={midColor}
          d="M1600 63.7c-149 27.5-280.1 41.2-393.3 41.2-113.2 0-248.4-13.7-405.7-41.2C521.8 15 254.8 7.1 0 40 117 23.6 263.5.8 410.6.8c98 0 228.2 13 390.4 38.8 177.2 28.7 312.4 43 405.7 43 91 0 222-14.3 393.3-43v24.1z"
        />
      </g>
    </svg>
  );

  /**
   * Good for color section end
   */
  const SwirlFromWhite = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1600 130"
      className={style.swirl__svg}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={toColor}
          d="M0 0h1600v90.4l-260.3 26.4-311.2-5.4-302.8-44.8-345.5-31.8L0 77.1z"
        />
        <path
          fill={midColor}
          d="M0 66.3C149 38.8 280.1 25 393.3 25c113.2 0 248.4 13.7 405.7 41.2 279.2 49 546.2 57 801 24.1-117 16.4-263.5 38.8-410.6 38.8-98 0-228.2-13-390.4-38.8-177.2-28.7-312.4-43-405.7-43-91 0-222 14.3-393.3 43V66.3z"
        />
      </g>
    </svg>
  );

  /**
   * Good for Hero banner and color section start
   */
  const ArchToWhite = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={style.swirl__svg}
      viewBox="0 0 1603 280"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={toColor}
          d="M0 75l435.9 73.6 364.6 14.8 386.5-27.8 415.7-103.8-2.7 247.7H0z"
        />
        <path
          fill={midColor2}
          d="M803.6 175.8C495 170.8 120 93 0 55v33c212.3 56.6 531.5 92.5 803.6 87.8zM938.1 171.2c265.9-22.8 433-59.9 662.9-120V84c-184.2 54.1-433 92-662.9 87.3z"
        />
        <path
          fill={midColor}
          d="M806.6 175.9C537 175.9 150.7 108.7 0 54.9c287.2 69.6 555.7 101.6 805.7 96.1 308.7-8.2 683.8-111 795.3-151v51.3c-202.3 59.1-524.2 124.6-794.4 124.6z"
        />
      </g>
    </svg>
  );

  const Svg = () => {
    switch (type) {
      case 'swirl from white':
        return <SwirlFromWhite />;
      case 'arch to white':
        return <ArchToWhite />;
      case 'swirl to white':
      default:
        return <SwirlToWhite />;
    }
  };

  return (
    <div className={[style.swirl, ...modifiers].join(' ')}>
      <Svg />
    </div>
  );
};

export default Swirl;
