import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './featured-card.mod.scss';
import { Button } from '@elements';
import uniqid from 'uniqid';
import Swiper from 'react-id-swiper';
import { t } from '../../helpers/language';
import { Next, Prev } from '../components/carousel';
import toSuperscript from '../../helpers/toSuperscript';
import Mask from '@svgs/mask-feature.svg';
import Mask2 from '@svgs/mask-feature-2.svg';

const FeaturedCard = ({
  id,
  heading = '',
  subHeading = '',
  caption = '',
  linkTo,
  moduleStyle = '',
  image,
  link,
  links = [],
  images = [],
  carousel = false
}) => {
  if (linkTo) links.push(linkTo);
  if (link) links.push(link);
  if (image) images.push(image);
  const isGallery = images.length > 1;
  const isMultiLinks = links.length > 1;
  const modifiers = [
    style['featured-card--' + moduleStyle.toLowerCase().replace(/ /gi, '-')],
    carousel && style['featured-card--carousel'],
    isMultiLinks && style['featured-card--multiLinks']
  ];

  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    renderPrevButton: () => <Prev />,
    renderNextButton: () => <Next />
  };

  return (
    <div className={[style[`featured-card`], ...modifiers].join(' ')}>
      <div className={[style[`featured-card__mask`]]}>
        {carousel ? <Mask2 /> : <Mask />}
        <div className={style[`featured-card__image`]}>
          {images.map(({ fluid }, i) => (
            <Img key={`featured-card-${heading}--${i}`} fluid={fluid} />
          ))}
        </div>
        {carousel ? (
          <svg viewBox="0 0 829 640">
            <clipPath id="svgPathTwo">
              <path
                fill="#79c9da"
                fill-rule="evenodd"
                d="M193.5 640.1c.5.4 212.3 2.8 635.5.1V1.5H71.7c-4.4 2.9-10.5 7.3-25.1 28.2a225 225 0 0 0-30 69.6C-58 381.1 143 594 143 594c16 19.2 32.8 34.5 50.5 46.1z"
                clip-rule="evenodd"
              />
            </clipPath>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 859 594"
          >
            <clipPath
              id="svgPath"
              clipPathUnits="objectBoundingBox"
              transform="scale(0.001164144 0.001683501)"
            >
              <defs>
                <path
                  d="m833.2 796.6-2.3 1.5-1.8 1.2c-111.2 73.5-234.9 108.3-379.6 86-3-.4-2.8-4.8.2-5a913 913 0 0 0 75.7-8.9 311.3 311.3 0 0 0 71.6-21c8.6-3.5 18.3-8.5 27.6-16.1 9.7-8.1 12.4-25.3-3.4-26.4-24.1-1.8-38.5 15-79.2 30.2-81.5 30.3-201.4 36.1-354.4-63.8a627.7 627.7 0 0 1-85.7-81c-2.4-2.7-98.3-13.5-98.3-16.6 0-89.4-8.1-328.3 0-425 4.8-56.5 0-212.6 9-244 7.2-24.8 595.4 19.8 659 4.6 59.3-14.2 117 8 126.5 11.1 56.2 18.8 102 52.6 141.5 100 23-26 48.8 3.2 70.8 34.6 21.1 30.4 30 69.8 30 69.8C1115 510.2 914 723.5 914 723.5a378.2 378.2 0 0 1-80.8 73.1Z"
                  id="a-mask"
                />
              </defs>
              <use
                fill="#79C9DA"
                transform="matrix(-1 0 0 1 1057 -298)"
                xlinkHref="#a-mask"
                fill-rule="evenodd"
              />
            </clipPath>
          </svg>
        )}
      </div>

      {/* {isGallery ? (
        <div className={style[`featured-card__images`]}>
          <Swiper {...params}>
            {images.map(({ fluid }, i) => (
              <Img
                className={style[`featured-card__slide`]}
                key={`featured-card-${heading}--${i}`}
                fluid={fluid}
                loading="lazy"
              />
            ))}
          </Swiper>
        </div>
      ) : (
        <div className={style[`featured-card__image`]}>
          {images.map(({ fluid }, i) => (
            <Img
              key={`featured-card-${heading}--${i}`}
              fluid={fluid}
              style={{ height: '100%' }}
            />
          ))}
        </div>
      )} */}

      <div className={style[`featured-card__content-wrap`]}>
        <div className={style[`featured-card__content`]}>
          <h2 dangerouslySetInnerHTML={toSuperscript(heading)}></h2>
          <h4 dangerouslySetInnerHTML={toSuperscript(subHeading)}></h4>
          <p dangerouslySetInnerHTML={toSuperscript(caption)}></p>
          <div className={style[`featured-card__links`]}>
            {links.map(
              ({ id, path, title = t('generic_cta'), ...linkProps }) => (
                <Button
                  key={uniqid()}
                  to={path}
                  {...linkProps}
                  fullWidthMobile={true}
                  light
                >
                  {title}
                </Button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCard;

export const query = graphql`
  fragment FeaturedCard on DatoCmsFeaturedCard {
    id
    model {
      apiKey
    }
    heading
    subHeading
    caption
    image {
      fluid(
        maxWidth: 860
        maxHeight: 600
        imgixParams: { fit: "crop", w: "860", h: "600" }
      ) {
        ...GatsbyDatoCmsFluid
      }
    }
    moduleStyle
    linkTo {
      ... on DatoCmsProduct {
        path
        title
      }
      ... on DatoCmsPage {
        path
        title
      }
      ... on DatoCmsCollection {
        path
        title
      }
    }
  }
`;
