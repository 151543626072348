import React from 'react';
import style from './banner.mod.scss';
import Img from 'gatsby-image';
import { Button } from '@elements';
import toSuperscript from '../../helpers/toSuperscript';
import { motion } from 'framer-motion';

const Banner = ({ title, caption, image, imageMobile, video }) => {
  const variants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  return (
    <motion.div variants={variants} className={style.banner}>
      <div className={style.banner__image}>
        <Img loading={'eager'} fluid={image.fluid} />
      </div>
      <div className={style.banner__image_mobile}>
        <Img loading={'eager'} fluid={imageMobile.fluid} />
      </div>
      <div className={style[`banner__content-wrapper`]}>
        <div className={style.banner__content}>
          <div className={style.banner__logo}>{/* <Logo /> */}</div>
          <h1 className={style.banner__title}>{title}</h1>
          <p
            className={style.banner__caption}
            dangerouslySetInnerHTML={toSuperscript(caption)}
          ></p>
          <div className={style.banner__cta}>
            <Button
              to="/all-products/"
              gaTarget="banner-chooser-cta"
              fullWidth={true}
              children={'Find your spa'}
              light
            />
          </div>
        </div>
      </div>
      <div className={style.banner__curve}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 92">
          <path
            d="M0 854.3h1440v46.6C1173 931 933 946 719.8 946 506.6 946 266.7 931 0 901v-46.7Z"
            transform="rotate(-180 720 473.1)"
            fill="#fff"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </motion.div>
  );
};

export default Banner;
