import React from 'react';
import style from './thumb.mod.scss';
import Img from 'gatsby-image';
import toSuperscript from '../../helpers/toSuperscript';

const Thumb = ({ image, title, icon, padded = false }) => {
  const modifier = padded ? style['thumb--padded'] : '';
  return (
    <div className={[style.thumb, modifier].join(' ')}>
      <div className={style.thumb__image}>
        {image && <Img loading="lazy" fluid={image.fluid} />}
      </div>
      <span className={style.thumb__text}>
        <span
          className={style.thumb__caption}
          dangerouslySetInnerHTML={toSuperscript(title)}
        ></span>
        <span className={style.thumb__icon}>{icon}</span>
      </span>
    </div>
  );
};

export default Thumb;
