import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './picture.mod.scss';

const Picture = ({ image, standAlone = false }) => {
  const modifier = standAlone ? style['picture--stand-alone'] : '';

  return (
    <div className={[style.picture, modifier].join(' ')}>
      <Img fluid={image.fluid} />
    </div>
  );
};

export default Picture;

export const query = graphql`
  fragment Picture on DatoCmsImage {
    image {
      fluid(maxWidth: 968, imgixParams: { w: "968" }) {
        ...GatsbyDatoCmsFluid
      }
    }
    background
    padding
    model {
      apiKey
      id
    }
  }
`;
