import React from 'react';
import { graphql } from 'gatsby';
import style from './video.mod.scss';

const Video = ({
  moduleStyle = 'Default',
  standAlone = false,
  video: { url, title, provider, providerUid },
  ...props
}) => {
  const iframeUrl = () => {
    switch (provider) {
      case 'youtube':
      default:
        return `https://www.youtube.com/embed/${providerUid}`;
    }
  };

  const modifiers = [
    style['video--' + moduleStyle.toLowerCase().replace(/ /gi, '-')],
    style[`video--${standAlone ? 'stand-alone' : 'partial'}`]
  ];
  return (
    <div className={[style.video, ...modifiers].join(' ')}>
      <div className={style.video__wrapper}>
        <iframe
          src={iframeUrl()}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default Video;

export const query = graphql`
  fragment Video on DatoCmsVideo {
    id
    padding
    background
    moduleStyle
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
    model {
      apiKey
    }
  }
`;
