import React from 'react';
import style from './divider.mod.scss';
import { graphql } from 'gatsby';

const Divider = ({ moduleStyle = 'default' }) => {
  const modifier = moduleStyle.toLowerCase().replace(/ /gi, '-');

  return (
    <div className={[style.divider, style['divider--' + modifier]].join(' ')}>
      <hr />
    </div>
  );
};

export default Divider;
export const query = graphql`
  fragment Divider on DatoCmsDivider {
    id
    moduleStyle
    model {
      apiKey
    }
    padding
    background
  }
`;
