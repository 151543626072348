import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layouts/default';
/* // Page transitions not performant - to be reinstated later
import { motion } from 'framer-motion';
import { TransitionState } from 'gatsby-plugin-transition-link';
import Link from 'gatsby-plugin-transition-link';
*/

const PageTransition = ({ children }) => {
  /* // Page transitions not performant - to be reinstated later
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1
      }
    },
    out: {
      opacity: 0,
      transition: {
        when: 'afterChildren'
      }
    }
  };
    return (
        <TransitionState>
            {({ mount, transitionStatus }) => {
                return (
                    <motion.div
                        initial="initial"
                        style={{ backgroundColor: 'white' }}
                        animate={mount ? 'in' : 'out'}
                        variants={pageVariants}
                    >
                        {children}
                    </motion.div>
                );
            }}
        </TransitionState>
    );
    */

  /** // Dumb page transition which just flashes the page
    return (
        <Layout>
            <motion.div
                initial="initial"
                animate={'in'}
                variants={pageVariants}
            >
                {children}
            </motion.div>
        </Layout>
    );*/

  return <Layout>{children}</Layout>;
};

const TransitionLink = props => {
  const config = {
    ...props
    /* // Page transitions not performant - to be reinstated later
        exit: {
            length: 0.5
        },
        entry: {
            delay: 0.2
        }
        */
  };

  return <Link {...config} />;
};

export { PageTransition, TransitionLink };
