import React from 'react';
import style from './features.mod.scss';

const Features = ({ items = [], simple = false }) => {
  console.log(items);
  const simpleItemsToShow = ['Seats', 'Jets', 'MSRP'];
  const simpleItems = items.filter(item =>
    simpleItemsToShow.includes(item.label)
  );

  const featureItems = simple ? simpleItems : items;

  return (
    <table className={style['features']}>
      <tbody>
        {featureItems.map((item, i) => (
          <Item key={`feature-table-item-${i}`} {...item} />
        ))}
      </tbody>
    </table>
  );
};

export default Features;

const Item = ({ label, value }) => {
  return value ? (
    <tr>
      <th scope="row">{label}</th>
      <td>{value}</td>
    </tr>
  ) : null;
};
