import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './product-intro.mod.scss';
import { Features } from '@partials';
import { getSpecsFromProduct } from '../../helpers/productHelpers';
import Scroll from '../../helpers/scroll';
import toSuperscript from '../../helpers/toSuperscript';

const ProductIntro = props => {
  const { displaySubtitle, shortDescription, heroImage, sectionNav } = props;

  const specs = getSpecsFromProduct(props);

  return (
    <div className={style['product-intro']}>
      <div className={style['product-intro__wrapper']}>
        <div className={style['product-intro__content']}>
          <h4 dangerouslySetInnerHTML={toSuperscript(displaySubtitle)}></h4>
          <p dangerouslySetInnerHTML={toSuperscript(shortDescription)}></p>
          <div className={style['product-intro__specs']}>
            <Features items={specs} />
          </div>
        </div>
        <div className={style['product-intro__image']}>
          <Img fluid={heroImage.fluid} />
        </div>
      </div>
      <div className={style['product-intro__nav-wrapper']}>
        <nav className={style['product-intro__nav']}>
          <ul>
            {sectionNav.map((section, i) => (
              <li key={`product-section-link-${section.id}`}>
                <Scroll type="id" element={section.id}>
                  <a href={`#${section.id}`}>{section.text}</a>
                </Scroll>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default ProductIntro;

export const query = graphql`
  fragment ProductIntro on DatoCmsProduct {
    heroImage {
      fluid(maxWidth: 796, imgixParams: { w: "796" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
      fixed(width: 244, height: 244, imgixParams: { w: "244", h: "244" }) {
        ...GatsbyDatoCmsFixed_noBase64
      }
    }
    title
    shortDescription
    displaySubtitle
  }
`;
