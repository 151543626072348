import React, { useState } from 'react';
import style from './checkbox.mod.scss';

const Checkbox = React.forwardRef(
  (
    { label, name, placeholder, ErrorMessage, required, inverted = false },
    ref
  ) => {
    const [isChecked, setCheck] = useState(false);

    const modifier = ErrorMessage ? style['checkbox--error'] : '';

    const inputConfig = {
      id: `checkbox--${name}`,
      name,
      ref,
      placeholder,
      required
    };

    return (
      <div className={[style.checkbox, modifier].join(' ')}>
        <input
          type="hidden"
          name={name}
          disabled={isChecked}
          value={!inverted ? 'No' : 'Yes'}
        />
        <input
          type="checkbox"
          checked={isChecked}
          {...inputConfig}
          onClick={() => setCheck(!isChecked)}
          value={!inverted ? 'Yes' : 'No'}
        />
        {label && (
          <label htmlFor={`input--${name}`}>
            {label}
            {required && <span area-label="required">*</span>}
          </label>
        )}
        {ErrorMessage && (
          <div className={style.checkbox__error}>
            <ErrorMessage />
          </div>
        )}
      </div>
    );
  }
);

export default Checkbox;
