import React from 'react';
import style from './intro.mod.scss';
import { Button, Breadcrumbs } from '@partials';
import { motion } from 'framer-motion';
import toSuperscript from '../../helpers/toSuperscript';
import LeftSvg from '@svgs/footer-left.svg';
import RightSvg from '@svgs/footer-right.svg';

const Intro = ({ title, action, breadcrumbs, children }) => {
  return (
    <div className={style.intro}>
      <div className={style.intro__left}>
        <LeftSvg />
      </div>
      <div className={style.intro__right}>
        <RightSvg />
      </div>
      <div className={style.intro__container}>
        <div className={style.intro__wrapper}>
          {breadcrumbs && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              className={style.intro__breadcrumbs}
            >
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </motion.div>
          )}
          {action && (
            <div className={style.intro__action}>
              <Button {...action} light />
            </div>
          )}
          {title && (
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              className={style.intro__title}
              dangerouslySetInnerHTML={toSuperscript(title)}
            ></motion.h1>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Intro;
