import React from 'react';
import { TransitionLink } from '../../helpers/pageTransitions';
import style from './breadcrumbs.mod.scss';
import uniqid from 'uniqid';
import toSuperscript from '../../helpers/toSuperscript';

const transitionSettings = {
  exit: {
    length: 0.5
  },
  entry: {
    delay: 0.2
  }
};

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <nav className={style.breadcrumbs} aria-label="Breadcrumb">
      <ul className={style.breadcrumbs__list}>
        {breadcrumbs &&
          breadcrumbs.map(({ path, title }) => (
            <li key={uniqid()} className={style.breadcrumbs__item}>
              <TransitionLink
                to={path}
                {...transitionSettings}
                dangerouslySetInnerHTML={toSuperscript(title)}
              ></TransitionLink>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
