import React from 'react';
import Img from 'gatsby-image';
import style from './swatch.mod.scss';

const Swatch = ({ onClick, isActive, swatch, name, id }) => {
  return (
    <button
      disabled={isActive}
      onClick={() => onClick}
      className={style.swatch}
    >
      <Img className={style.swatch__image} fixed={swatch.fixed} />
      <div className={style.swatch__name}>{name}</div>
    </button>
  );
};

export default Swatch;
