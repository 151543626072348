import { t } from './language';

const getSpecsFromProduct = ({
  seating,
  lounge,
  jets,
  dimensions,
  capacity,
  price,
  ...props
}) => {
  return [
    {
      label: t('seats_label'),
      value: seating
    },
    {
      label: t('lounge_seat_label'),
      value: lounge
    },
    {
      label: t('jets_label'),
      value: jets
    },
    {
      label: t('dimensions_label'),
      value: dimensions
    },
    {
      label: t('capacity_label'),
      value: capacity
    },
    {
      label: t('price_label'),
      value: price
    }
  ];
};

const getCompareAttrFromProduct = () => [
  {
    label: t('seats_label'),
    key: 'seating'
  },
  {
    label: t('lounge_seat_label'),
    key: 'lounge'
  },
  {
    label: t('jets_label'),
    key: 'jets'
  },
  {
    label: t('dimensions_label'),
    key: 'dimensions'
  },
  {
    label: t('capacity_label'),
    key: 'capacity'
  },
  {
    label: t('price_label'),
    key: 'price'
  }
];

export { getSpecsFromProduct, getCompareAttrFromProduct };
