import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { menuTreeFromPages } from '../helpers/menuHelpers';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import { Header, Footer } from '@components';
import { Drilldown } from '@elements';
import typography from '../helpers/typography';
import ThemeContext from '../context/themeContext';
import { motion } from 'framer-motion';
import 'react-use-what-input';
import '../assets/scss/styles.global.scss';
import { HelmetDatoCms } from 'gatsby-source-datocms';

const isBrowser = () => typeof window !== 'undefined' && window;

const Layout = props => {
  const { children } = props;
  typography.injectStyles();

  const { site, allSitePage } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          lang
        }
      }
      allSitePage {
        nodes {
          ...MenuAllPages
        }
      }
      allDatoCmsConfig {
        edges {
          node {
            id
            contactPage {
              id
              locale
            }
          }
        }
      }
    }
  `);

  const menuItems = menuTreeFromPages(allSitePage.nodes);

  const mql = isBrowser() ? window.matchMedia('(min-width: 1024px)') : false;

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at calc(100% - 35px) 40px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: 'circle(0px at calc(100% - 32px) 48px)',
      transition: {
        delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40
      }
    }
  };

  const sidebarWrapper = {
    open: {
      display: 'block'
    },
    closed: {
      display: 'none',
      transition: {
        delay: 1.5
      }
    }
  };

  return (
    <ThemeContext.Consumer>
      {({ isSidebarOpen, closeSidebar }) => {
        const isDesktop = ({ matches }) => {
          if (matches) {
            closeSidebar();
          }
        };

        mql && mql.addListener(isDesktop);

        return (
          <div
            id="layout"
            className={`layout layout--sidebar-${
              isSidebarOpen ? 'open' : 'close'
            }`}
          >
            <HelmetDatoCms>
              <meta name="format-detection" content="telephone=no" />
              <html lang={site.siteMetadata.lang} />
            </HelmetDatoCms>
            <Header menuItems={menuItems} />
            <div className={'layout__content'}>
              <main>{children}</main>
              <Footer />
            </div>
            <motion.div
              className={'layout__sidebar-bg-wrapper'}
              variants={sidebarWrapper}
              animate={isSidebarOpen ? 'open' : 'closed'}
            >
              <motion.div
                dragPropagation
                animate={isSidebarOpen ? 'open' : 'closed'}
                className={'layout__sidebar-bg'}
                variants={sidebar}
              />
            </motion.div>
            <motion.div
              className={'layout__sidebar'}
              initial={false}
              animate={isSidebarOpen ? 'open' : 'closed'}
              variants={{
                open: {
                  display: 'block'
                },
                closed: {
                  transition: {
                    delay: 1
                  },
                  display: 'none'
                }
              }}
            >
              <motion.div className={'layout__sidebar-scroll'}>
                <FocusLock group="sidebar" disabled={!isSidebarOpen}>
                  <Drilldown
                    isVisible={isSidebarOpen}
                    items={menuItems}
                    closeSidebar={closeSidebar}
                  />
                </FocusLock>
              </motion.div>
            </motion.div>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
