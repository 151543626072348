import React from 'react';
import style from './grid.mod.scss';
import { Lead } from '@partials';

const Grid = ({
  heading = false,
  lead = false,
  children,
  headingLevel = 2,
  moduleStyle = 'default'
}) => {
  const modifier = moduleStyle.toLowerCase().replace(/ /gi, '-');

  const HeadingTag = () => {
    const config = { className: style.list__heading };
    switch (headingLevel) {
      case 4:
        return <h4 {...config}>{heading}</h4>;
      case 3:
        return <h3 {...config}>{heading}</h3>;
      case 2:
      default:
        return <h2 {...config}>{heading}</h2>;
    }
  };

  return (
    <div className={[style.grid, style['grid--' + modifier]].join(' ')}>
      {heading && (
        <HeadingTag className={style.grid__heading}>{heading}</HeadingTag>
      )}
      {lead && <Lead>{lead}</Lead>}
      <div className={style.grid__wrapper}>{children}</div>
    </div>
  );
};

export default Grid;
