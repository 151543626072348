import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import Img from 'gatsby-image';
import style from './carousel.mod.scss';
import { Lead, Thumb, Button, FeaturedCard } from '@elements';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { IoIosEye } from 'react-icons/io';
import { graphql } from 'gatsby';
import toSuperscript from '@helpers/toSuperscript';

const Prev = () => (
  <button aria-label="Previous" className="swiper-button-prev">
    <MdKeyboardArrowLeft />
  </button>
);

const Next = () => (
  <button aria-label="Next" className="swiper-button-next">
    <MdKeyboardArrowRight />
  </button>
);

const defaultConfig = {
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction'
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  renderPrevButton: () => <Prev />,
  renderNextButton: () => <Next />
};

const Carousel = props => {
  const {
    heading,
    leadText,
    items,
    moduleStyle = 'default',
    showThumbNavigation = false
  } = props;

  const [mainSwiper, getMainSwiper] = useState(null);

  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  const modifiers = [
    style['carousel--' + moduleStyle.toLowerCase().replace(/ /gi, '-')],
    style[`carousel--${showThumbNavigation ? 'thumbs' : 'no-thumbs'}`]
  ];

  const mainParams = {
    ...defaultConfig,
    observer: true,
    observeParents: true,
    breakpoints: {
      320: {
        autoHeight: true
      },
      1024: {
        autoHeight: false
      }
    }
  };

  const thumbnailParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 20,
    slidesPerView: 4,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    centeredSlides: true,
    observer: true,
    observeParents: true
  };

  if (showThumbNavigation) mainParams.getSwiper = getMainSwiper;

  const Slide = ({
    caption,
    heading,
    defaultImage,
    featureImage,
    subheading = '',
    link = false
  }) => (
    <div className={style.carousel__slide}>
      <div className={style.carousel__image}>
        {defaultImage && <Img loading="lazy" fluid={defaultImage.fluid} />}
      </div>
      <div className={style.carousel__content}>
        {heading && (
          <h2
            className={style.carousel__heading}
            dangerouslySetInnerHTML={toSuperscript(heading)}
          ></h2>
        )}
        {subheading && (
          <h4
            className={style.carousel__subheading}
            dangerouslySetInnerHTML={toSuperscript(subheading)}
          ></h4>
        )}
        {caption && <p dangerouslySetInnerHTML={toSuperscript(caption)}></p>}
        {link && <Button to={link.path}>{link.title}</Button>}
      </div>
    </div>
  );

  useEffect(() => {
    if (
      mainSwiper !== null &&
      mainSwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller &&
      showThumbNavigation
    ) {
      mainSwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = mainSwiper;
    }
  }, [mainSwiper, thumbnailSwiper, showThumbNavigation]);

  return (
    <div className={[style.carousel, ...modifiers].join(' ')}>
      {(heading || leadText) && (
        <div className={style.carousel__top}>
          {heading && (
            <h2 dangerouslySetInnerHTML={toSuperscript(heading)}></h2>
          )}
          {leadText && <Lead body={leadText} />}
        </div>
      )}

      <Swiper {...mainParams}>
        {items.map((item, i) => (
          <div key={`${heading}-slide--${i}`}>
            {moduleStyle === 'Features' ? (
              <FeaturedCard
                {...item}
                image={item.featureImage}
                moduleStyle={'Image Left'}
                carousel
              />
            ) : (
              <Slide {...item} />
            )}
          </div>
        ))}
      </Swiper>
      {showThumbNavigation && (
        <Swiper {...thumbnailParams}>
          {items.map(({ heading, featureImage, defaultImage }, i) => (
            <div
              className={style.carousel__thumb}
              key={`${heading}-slide-thumb--${i}`}
            >
              <Thumb
                title={heading}
                image={moduleStyle === 'Features' ? featureImage : defaultImage}
                icon={<IoIosEye />}
                padded={moduleStyle.toLowerCase() === 'products'}
              />
            </div>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default Carousel;

export { Prev, Next };

export const query = graphql`
  fragment Carousel on DatoCmsCarousel {
    id
    model {
      apiKey
    }
    heading
    leadText
    moduleStyle
    background
    padding
    showThumbNavigation
    carouselItems {
      ...CarouselItems
    }
  }

  fragment CarouselItems on DatoCmsCarouselItem {
    caption
    heading
    defaultImage: image {
      fluid(
        maxWidth: 778
        imgixParams: { w: "778", h: "472", fit: "crop", q: 70 }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
      fixed(
        height: 222
        width: 350
        imgixParams: { w: "350", h: "222", fit: "crop", q: 70 }
      ) {
        ...GatsbyDatoCmsFixed
      }
    }
    featureImage: image {
      fluid(maxWidth: 1600, imgixParams: { w: "1600", q: 70 }) {
        ...GatsbyDatoCmsFluid
      }
      alt
      fixed(
        height: 222
        width: 350
        imgixParams: { w: "350", h: "222", fit: "crop", q: 70 }
      ) {
        ...GatsbyDatoCmsFixed
      }
    }
  }

  fragment ProductCarousel on DatoCmsHome {
    productCarousel {
      path
      title
      displaySubtitle
      shortDescription
      id
      heroImage {
        fluid(
          maxWidth: 778
          imgixParams: {
            w: "778"
            h: "472"
            fit: "fill"
            fill: "solid"
            fillColor: "00ffffff"
          }
        ) {
          ...GatsbyDatoCmsFluid_noBase64
        }
        alt
      }
    }
  }

  fragment Slide on DatoCmsSlide {
    id
    caption
    heading
    featureImage: image {
      fluid(maxWidth: 1600, imgixParams: { w: "1600", q: 70 }) {
        ...GatsbyDatoCmsFluid
      }
      alt
      fixed(
        height: 222
        width: 350
        imgixParams: { w: "350", h: "222", fit: "crop", q: 70 }
      ) {
        ...GatsbyDatoCmsFixed
      }
    }
    link {
      ... on DatoCmsPage {
        id
        path
        title
      }
      ... on DatoCmsProduct {
        id
        path
        title
      }
      ... on DatoCmsCollection {
        id
        path
        title
      }
      ... on DatoCmsProductLandingPage {
        id
        title
        path
      }
    }
  }
`;
