import React, { useContext } from 'react';
import style from './header.mod.scss';
import { Menu } from '@components';
import { MenuIcon } from '@elements';
import Headroom from 'react-headroom';
import ThemeContext from '../../context/themeContext';
import { t } from '../../helpers/language';
import FocusLock from 'react-focus-lock';
import FisherLogo from '@svgs/logo.svg';
import { Link } from 'gatsby';

const Header = ({ menuItems }) => {
  const { toggleSidebar, isSidebarOpen } = useContext(ThemeContext);

  return (
    <FocusLock group="sidebar" disabled={!isSidebarOpen}>
      <header className={style.header}>
        <Headroom
          disableInlineStyles
          className={isSidebarOpen ? 'headroom-wrapper--sidebar' : ''}
        >
          <div className={style.header__bg}>
            <div className={style.header__container}>
              <div className={style.header__wrapper}>
                <div className={style.header__logo}>
                  <Link to={'/'}>
                    <FisherLogo />
                  </Link>
                </div>

                <div className={style.header__nav}>
                  <Menu items={menuItems} />
                </div>
                <div className={style.header__hamburger}>
                  <MenuIcon toggle={toggleSidebar} isOpen={isSidebarOpen} />
                </div>
              </div>
            </div>
          </div>
        </Headroom>
      </header>
    </FocusLock>
  );
};

export default Header;
