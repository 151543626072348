import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './content.mod.scss';
import Swiper from 'react-id-swiper';
import { Video, Button } from '@partials';
import { Next, Prev } from '../components/carousel';
import { t } from '../../helpers/language';
import toSuperscript from '../../helpers/toSuperscript';
const Content = ({
  id,
  heading,
  contentNode: {
    childMarkdownRemark: { html }
  },
  images,
  video,
  link,
  externalLink,
  linkText = t('generic_cta'),
  type = 'simple',
  moduleStyle = 'default'
}) => {
  const renderMedia = () => {
    let media = null;

    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      renderPrevButton: () => <Prev />,
      renderNextButton: () => <Next />
    };

    switch (type) {
      case 'images':
        media = images.map(({ fluid }, i) => (
          <div
            key={`content-${id}-media-${i}`}
            className={style.content__image}
          >
            <Img fluid={fluid} />
          </div>
        ));
        break;
      case 'carousel':
        media = (
          <div className={style.content__carousel}>
            <Swiper
              {...params}
              children={images.map(({ fluid }, i) => (
                <Img key={`content-${id}-media-${i}`} fluid={fluid} />
              ))}
            />
          </div>
        );
        break;
      case 'video':
        media = (
          <div className={style.content__video}>
            <Video key={`content-${id}-media`} {...video} />
          </div>
        );
        break;
      default:
    }

    return media;
  };

  const modifiers = [
    style['content--' + moduleStyle.toLowerCase().replace(/ /gi, '-')],
    style['content--' + type.toLowerCase().replace(/ /gi, '-')]
  ];

  const buttonConfig = {
    to: link ? link.path : externalLink,
    children: linkText
  };

  if (externalLink) {
    buttonConfig.target = '_blank';
    buttonConfig.rel = 'noopener noreferrer';
  }

  return (
    <div className={[style.content, ...modifiers].join(' ')}>
      <div className={style.content__wrapper}>
        {renderMedia()}
        <div className={style.content__body}>
          {heading && (
            <h3
              className={style.content__heading}
              dangerouslySetInnerHTML={toSuperscript(heading)}
            ></h3>
          )}
          {html && (
            <div
              className={style.content__caption}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
          {(link || externalLink) && (
            <div className={style.content__link}>
              <Button {...buttonConfig} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;

export const query = graphql`
  fragment Content on DatoCmsContent {
    id
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    moduleStyle
    link {
      ... on DatoCmsProduct {
        path
        title
      }
      ... on DatoCmsPage {
        path
      }
      ... on DatoCmsCollection {
        path
        title
      }
    }
    externalLink
    linkText
    background
    padding
    model {
      apiKey
    }
  }

  fragment ContentWithImage on DatoCmsContentWithImage {
    id
    model {
      apiKey
    }
    heading
    moduleStyle
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    images {
      fluid(maxWidth: 720, imgixParams: { h: "405", w: "720", fit: "crop" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
  }

  fragment ContentWithVideo on DatoCmsContentWithVideo {
    id
    model {
      apiKey
    }
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    moduleStyle
    heading
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }

  fragment ContentWithCarousel on DatoCmsContentWithCarousel {
    id
    model {
      apiKey
    }
    heading
    moduleStyle
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    images {
      fluid(maxWidth: 720, imgixParams: { h: "405", w: "720", fit: "crop" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
  }
`;
