import React from 'react';
import PropTypes from 'prop-types';
import style from './block.mod.scss';

const Block = ({ children, padding = 'None', background = 'None' }) => {
  const modifiers = [
    style[
      'block--padding-' +
        padding
          .trim()
          .toLowerCase()
          .replace(/ /gi, '-')
    ],
    style[
      'block--bg-' +
        background
          .trim()
          .toLowerCase()
          .replace(/ /gi, '-')
    ]
  ];

  return (
    <div className={[style.block, ...modifiers].join(' ')}>{children}</div>
  );
};

Block.propTypes = {
  children: PropTypes.node.isRequired
};

export default Block;
