import React from 'react';
import style from './card-list.mod.scss';

const CardList = ({ children, fullWidth = false }) => {
  const modifiers = [
    style[`card-list--${fullWidth ? 'full-width' : 'default'}`]
  ];

  return (
    <aside className={[style['card-list'], ...modifiers].join(' ')}>
      <div className={style[`card-list__container`]}>{children}</div>
    </aside>
  );
};

export default CardList;
