import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './list.mod.scss';
import { FaChevronRight } from 'react-icons/fa';
import toSuperscript from '../../helpers/toSuperscript';

const List = ({
  id,
  heading = '',
  items = [],
  moduleStyle = 'default',
  headingLevel = 3,
  image
}) => {
  const modifier = moduleStyle.toLowerCase().replace(/ /gi, '-');

  const HeadingTag = () => {
    const config = { className: style.list__heading };
    switch (headingLevel) {
      case 4:
        return (
          <h4 {...config} dangerouslySetInnerHTML={toSuperscript(heading)} />
        );
      case 3:
        return (
          <h3 {...config} dangerouslySetInnerHTML={toSuperscript(heading)} />
        );
      case 2:
      default:
        return (
          <h2 {...config} dangerouslySetInnerHTML={toSuperscript(heading)} />
        );
    }
  };

  return (
    <div className={[style.list, style['list--' + modifier]].join(' ')}>
      {image && (
        <div className={style[`list__image-wrapper`]}>
          <Img className={style.list__image} fluid={image.fluid} />
        </div>
      )}
      {heading && <HeadingTag className={style.list__heading} />}

      <ul className={style.list__list}>
        {items.map(({ url, title, alt }, i) => (
          <li key={`list-${id}-item-${i}`} className={style.list__item}>
            <a
              className={style.list__link}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
            >
              <span
                className={style.list__text}
                dangerouslySetInnerHTML={toSuperscript(title)}
              />
              <span className={style.list__icon}>
                <FaChevronRight />
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;

export const query = graphql`
  fragment DocumentsList on DatoCmsDocumentList {
    id
    heading
    documents {
      url
      title
      alt
      mimeType
    }
    model {
      apiKey
    }
    padding
    background
  }

  fragment DocumentGroup on DatoCmsDocumentGroupList {
    heading
    documentGroup {
      documents {
        url
        title
        alt
      }
      id
      groupName
      image {
        fluid(
          maxWidth: 1600
          maxHeight: 660
          imgixParams: {
            w: "500"
            h: "300"
            fit: "fill"
            fill: "solid"
            fillColor: "00ffffff"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    model {
      apiKey
    }
    padding
    background
  }
`;
