import React from 'react';
import style from './lead.mod.scss';
import { motion } from 'framer-motion';
import toSuperscript from '../../helpers/toSuperscript';

const Lead = ({ body, animate = false }) => {
  const animationConfig = animate
    ? {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.7 }
      }
    : {};

  return (
    <div className={style.lead}>
      <div className={style.lead__container}>
        {body && (
          <motion.p
            {...animationConfig}
            dangerouslySetInnerHTML={toSuperscript(body)}
          ></motion.p>
        )}
      </div>
    </div>
  );
};

export default Lead;
