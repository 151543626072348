import React, { Component } from 'react';
import { MenuItem } from '@partials';
import style from './drilldown.mod.scss';
import { motion } from 'framer-motion';

export default class Drilldown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curMenu: 'drilldown__list--index',
      curDepth: 0,
      curChildMenu: [],
      curChildHeading: ''
    };
  }

  returnToIndex() {
    this.setState({
      curChildMenu: [],
      curChildHeading: '',
      curDepth: 0,
      curMenu: 'drilldown__list--index'
    });
  }

  setChildMenu(items, id, heading = '') {
    this.setState({
      curChildMenu: items,
      curDepth: 1,
      curChildHeading: heading
    });
  }

  megaMenuItems({ id, path, title, megaMenu }, i, curDepth) {
    const html = [
      <MenuItem
        key={`drilldown__item-${i}`}
        dataKey={`drilldown__item-${i}`}
        path={path}
        modifier={'drilldown'}
        title={`All ${title}`}
        vertical={true}
        tabIndex={curDepth === 0 ? 0 : -1}
        index={i}
        onClick={() => this.close()}
      />
    ];

    megaMenu.forEach(({ id, heading, collections, showAllText }, ii) => {
      html.push(
        <MenuItem
          key={`drilldown__item-${i}-${ii}`}
          dataKey={`drilldown__item-${i}-${ii}`}
          modifier={'drilldown'}
          path={path}
          title={heading}
          onClick={() => this.setChildMenu(collections, `${id}-${ii}`, heading)}
          isButton={true}
          showIcon={true}
          vertical={true}
          tabIndex={curDepth === 0 ? 0 : -1}
          index={ii}
        />
      );
    });

    return html;
  }

  close() {
    this.props.closeSidebar();
    this.returnToIndex();
  }

  render() {
    const { items, isVisible } = this.props;
    const { curDepth, curChildMenu, curChildHeading } = this.state;

    const variants = {
      open: {
        display: 'block',
        opacity: 1,
        y: 0,
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
      },
      closed: {
        display: 'none',
        opacity: 0,
        y: -16,
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
      }
    };

    return (
      <div className={style.drilldown}>
        <motion.ul
          id={`drilldown__list--index`}
          initial={'closed'}
          animate={isVisible && curDepth === 0 ? 'open' : 'closed'}
          variants={variants}
          className={style.drilldown__list}
        >
          {items.map((item, i) => {
            const { id, path, title, megaMenu = [], treeChildren = [] } = item;

            const isMegaMenu = megaMenu.length > 0;
            const hasChildren = treeChildren.length > 0;

            return isMegaMenu ? (
              this.megaMenuItems(item, i, curDepth)
            ) : (
              <MenuItem
                key={`drilldown__item-${path}-${i}`}
                dataKey={`drilldown__item-${path}-${i}`}
                modifier={'drilldown'}
                path={path}
                onClick={
                  hasChildren
                    ? () => {
                        this.setChildMenu([item, ...treeChildren], id);
                      }
                    : () => this.close()
                }
                isButton={hasChildren ? true : false}
                title={title}
                showIcon={isMegaMenu || hasChildren}
                vertical={true}
                tabIndex={curDepth === 0 ? 0 : -1}
                index={i}
              />
            );
          })}
        </motion.ul>
        {curChildMenu.length > 0 && (
          <motion.ul
            initial={'closed'}
            animate={isVisible && curDepth === 1 ? 'open' : 'closed'}
            variants={{
              open: {
                opacity: 1,
                y: 0,
                transition: {
                  staggerChildren: 0.07,
                  delayChildren: 0.5
                }
              },
              closed: {
                opacity: 0,
                y: -16,
                transition: {
                  staggerChildren: 0.05,
                  staggerDirection: -1
                }
              }
            }}
            className={style.drilldown__child}
          >
            {curChildHeading !== '' && (
              <motion.li
                className={style.drilldown__subheading}
                variants={variants}
              >
                <h4>{curChildHeading}</h4>
              </motion.li>
            )}
            {curChildMenu.map(
              ({ path, slug, title, parentLandingPage }, iii) => {
                const safePath =
                  parentLandingPage !== undefined
                    ? `/${parentLandingPage.slug}/${slug}`
                    : path;

                return (
                  <MenuItem
                    key={`drilldown__child-item-${path}-${iii}`}
                    modifier={'drilldown'}
                    dataKey={`drilldown__child-item-${path}-${iii}`}
                    path={safePath}
                    title={title}
                    vertical={true}
                    index={iii}
                    tabIndex={curDepth === 1 ? 0 : -1}
                    onClick={() => this.close()}
                  />
                );
              }
            )}

            <MenuItem
              modifier={'drilldown'}
              key={`drilldown__child-item-return`}
              dataKey={`drilldown__child-item-return`}
              title={'Back'}
              backIcon={true}
              path={'/'}
              onClick={() => this.returnToIndex()}
              isButton={true}
              vertical={true}
              index={100}
              tabIndex={curDepth === 1 ? 0 : -1}
            />
          </motion.ul>
        )}
      </div>
    );
  }
}
