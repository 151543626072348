import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './contact.mod.scss';
import {
  VortexLeisureLogo,
  Input,
  Select,
  Textarea,
  Checkbox,
  Button,
  Loading
} from '@partials';
import { t } from '../../helpers/language';
import { motion, AnimatePresence } from 'framer-motion';
import toSuperscript from '../../helpers/toSuperscript';
import smoothscroll from 'smoothscroll-polyfill';

const Contact = ({
  heading,
  leadTextNode,
  successMessage,
  failureMessage,
  product = false,
  formType = 'contact'
}) => {
  const [formState, setFormState] = useState('ready');

  const formName = formType;

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const onSuccess = response => {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: 'enquirySent',
        enquiryType: formType,
        product: product ? product.name : ''
      });
    }

    console.log('Form successfully submitted', response);
    setFormState('success');
  };

  const handleSubmit = e => {
    e.preventDefault();

    console.log(encodeURIComponent);
    let myForm = document.getElementById('contactForm');
    let formData = new FormData(myForm);
    setFormState('loading');
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(response => {
        if (response.status === 200) onSuccess(response);
        console.log(response);
      })
      .catch(error => {
        setFormState('error');
        console.log(error);
      });

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <form
      id="contactForm"
      name={formName}
      method="POST"
      data-netlify="true"
      onSubmit={handleSubmit}
    >
      <AnimatePresence>
        {formState === 'error' && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className={style.contact__surface}
          >
            <div className={style.contact__body}>
              <center>{failureMessage}</center>
            </div>
          </motion.div>
        )}
        {formState !== 'success' && formState !== 'loading' && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={style.contact__surface}
          >
            <ContactHeader heading={heading} />
            <div className={style.contact__body}>
              {product && (
                <>
                  <h4
                    className={style.contact__subheading}
                    dangerouslySetInnerHTML={toSuperscript(
                      t('contact_product_heading')
                    )}
                  ></h4>
                  <fieldset className={style.contact__fieldset}>
                    {/* // TODO:   replace with product card */}
                    <div className={style.contact__product}>
                      <div className={style['contact__product-content']}>
                        <h2
                          dangerouslySetInnerHTML={toSuperscript(product.title)}
                        ></h2>
                        <p
                          dangerouslySetInnerHTML={toSuperscript(
                            product.shortDescription
                          )}
                        ></p>
                      </div>
                      <div className={style['contact__product-image']}>
                        <Img fluid={product.heroImage.fluid} />
                      </div>
                    </div>
                  </fieldset>
                </>
              )}
              {leadTextNode?.childMarkdownRemark?.html && (
                <div
                  className={style.contact__lead}
                  dangerouslySetInnerHTML={{
                    __html: leadTextNode.childMarkdownRemark.html
                  }}
                />
              )}

              <h4 className={style.contact__subheading}>
                {t('contact_details_heading')}
              </h4>
              <fieldset className={style.contact__fieldset}>
                <ContactForm product={product} type={formType} />
              </fieldset>
            </div>
          </motion.div>
        )}
        {formState === 'loading' && <Loading />}
        {formState === 'success' && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className={style.contact__surface}
          >
            <div className={style.contact__body}>
              <center>{successMessage}</center>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
};

export default Contact;

const ContactHeader = ({ heading }) => (
  <div className={style.contact__header}>
    <h2 className={style.contact__heading}>{heading}</h2>
    <div className={style.contact__logo}>
      <VortexLeisureLogo />
    </div>
  </div>
);

const ContactForm = ({ type, product }) => {
  return (
    <>
      <input type="hidden" name="form-name" value={type ? type : 'contact'} />

      <input type="hidden" name="type" value={type ? type : 'contact'} />

      <input
        type="hidden"
        name="product"
        value={product ? product.title : ''}
      />

      <input
        type="hidden"
        name="productTag"
        value={product ? product?.infusionsoftTag : ''}
      />

      <input
        type="hidden"
        name="productType"
        value={product ? product?.parentLandingPage?.infusionsoftTag : ''}
      />

      {type === 'contact' && (
        <Select
          label={t('contact_preference')}
          name="preference"
          options={[
            {
              value: 'email',
              text: 'Email'
            },
            {
              value: 'phone',
              text: 'Phone'
            }
          ]}
        />
      )}

      <Input
        label={t('contact_name')}
        name="name"
        required
        placeholder={t('contact_name_placeholder')}
      />
      <Input
        label={t('contact_email')}
        type="email"
        name="email"
        required
        placeholder={t('contact_email_placeholder')}
      />
      <Input
        label={t('contact_phone')}
        type="tel"
        name="phone"
        required
        placeholder={t('contact_phone_placeholder')}
      />

      {type === 'warranty-registration' && (
        <Input
          label={t('contact_order_number')}
          name="orderNumber"
          required
          placeholder={t('contact_order_number_placeholder')}
        />
      )}

      {type === 'warranty-registration' && (
        <Input
          label={t('contact_product_model')}
          name="productModel"
          required
          placeholder={t('contact_product_model_placeholder')}
        />
      )}

      {type === 'warranty-registration' && (
        <Input
          label={t('contact_serial_number')}
          name="serialNumber"
          required
          placeholder={t('contact_serial_number_placeholder')}
        />
      )}

      {type === 'warranty-registration' && (
        <Input
          label={t('contact_purchase_date')}
          name="purchaseDate"
          type="date"
          required
          placeholder={t('contact_purchase_date_placeholder')}
        />
      )}

      {type === 'warranty-registration' && (
        <Input
          label={t('contact_delivery_date')}
          name="deliveryDate"
          type="date"
          required
          placeholder={t('contact_delivery_date_placeholder')}
        />
      )}

      {type === 'contact' && (
        <Input
          label={t('contact_postcode')}
          name="postcode"
          required
          placeholder={t('contact_postcode_placeholder')}
        />
      )}

      {type === 'contact' && (
        <Textarea
          label={t('contact_message')}
          name="message"
          placeholder={t('contact_message_placeholder')}
        />
      )}

      <Checkbox label={t('contact_opt_in')} name={'optin'} inverted={true} />

      <Button type="submit">{t('contact_submit')}</Button>
    </>
  );
};

export const query = graphql`
  fragment Contact on DatoCmsContactForm {
    id
    model {
      apiKey
    }
    heading
    formType
    leadTextNode {
      childMarkdownRemark {
        html
      }
    }
    successMessage
    failureMessage
    padding
    background
  }
`;
