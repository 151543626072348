import React from 'react';
import style from './input.mod.scss';

const Input = React.forwardRef(
  (
    { label, name, type = 'text', placeholder, ErrorMessage, required },
    ref
  ) => {
    const modifier = ErrorMessage ? style['input--error'] : '';

    const inputConfig = {
      id: `input--${name}`,
      type,
      name,
      ref,
      placeholder,
      required
    };

    return (
      <div className={[style.input, modifier].join(' ')}>
        {label && (
          <label htmlFor={`input--${name}`}>
            {label}
            {required && <span area-label="required">*</span>}
          </label>
        )}
        <input {...inputConfig} />
        {ErrorMessage && (
          <div className={style.input__error}>
            <ErrorMessage />
          </div>
        )}
      </div>
    );
  }
);

export default Input;
