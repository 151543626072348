const menuTreeFromPages = nodes => {
  const allMenuItems = nodes
    .filter(({ context }) => context !== null && context.showPage)
    .map(({ path, context }) => ({ path: path, ...context }));

  const datoCollections = allMenuItems.filter(
    ({ type }) => type === 'DatoCmsCollection'
  );

  // const dataLandingPages = allMenuItems
  //   .filter(({ type }) => type === 'DatoCmsProductLandingPage')
  //   // .sort((a, b) => a.position - b.position)
  //   .map(item => {
  //     return {
  //       ...item,
  //       treeChildren: datoCollections.filter(
  //         ({ treeParent }) => treeParent.slug === item.slug
  //       )
  //     };
  //   });

  const datoPages = allMenuItems
    .filter(
      ({ treeParent, type }) =>
        (!treeParent || !treeParent.id) && type === 'DatoCmsPage'
    )
    .sort((a, b) => a.position - b.position);

  datoPages.forEach(parent => {
    const { treeChildren, path } = parent;

    if (treeChildren && treeChildren.length > 0) {
      parent.treeChildren = treeChildren
        .map(child => ({
          ...child,
          path: `${path}${child.slug}`
        }))
        .sort((a, b) => a.position - b.position);
    }
  });

  return [...datoCollections, ...datoPages];
};

export { menuTreeFromPages };
