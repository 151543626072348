import React from 'react';
import style from './section.mod.scss';
import { Lead } from '@elements';
import toSuperscript from '../../helpers/toSuperscript';
const Section = ({
  id,
  heading,
  lead,
  children,
  animate = false,
  background = 'none',
  padding = 'none',
  isolated,
  overflow
}) => {
  const modifiers = [
    style[`section--bg-${background.toLowerCase().replace(/ /gi, '-')}`],
    style[`section--padding-${padding.toLowerCase().replace(/ /gi, '-')}`],
    isolated && style[`section--padding-isolated`],
    overflow && style[`section--overflow`]
  ];

  return (
    <section id={id} className={[style.section, ...modifiers].join(' ')}>
      {(heading || lead) && (
        <div className={style.section__intro}>
          {heading && (
            <h2 dangerouslySetInnerHTML={toSuperscript(heading)}></h2>
          )}
          {lead && <Lead body={lead} />}
        </div>
      )}
      {children}
    </section>
  );
};

export default Section;
