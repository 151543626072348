import React, { useState } from 'react';
import style from './menu.mod.scss';
import { Link, graphql } from 'gatsby';
import { DropDown } from '@elements';
import toSuperscript from '../../helpers/toSuperscript';

const Menu = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const transitionSettings = {
    exit: {
      length: 0.5
    },
    entry: {
      delay: 0.2
    }
  };

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? {
          className: [style.menu__link, style['menu__link--parent']].join(' ')
        }
      : null;
  };

  const linkConfig = {
    partiallyActive: true,
    className: style.menu__link,
    activeClassName: style['menu__link--current'],
    ...transitionSettings
  };

  const handleMouseEnter = curIndex => {
    if (activeIndex !== curIndex) setActiveIndex(curIndex);
  };

  console.log(items);

  return (
    <nav className={style.menu}>
      <ul className={style.menu__list}>
        {items.map((item, index) => {
          const { title, path, treeChildren } = item;

          return (
            <li
              key={`menu__list--${index}`}
              className={style.menu__item}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => setActiveIndex(-1)}
            >
              {treeChildren && treeChildren.length > 0 ? (
                <Link
                  {...linkConfig}
                  to={path}
                  getProps={isPartiallyActive}
                  onFocus={() => setActiveIndex(index, true)}
                  aria-haspopup="true"
                  aria-controls={`menu-${index}-box`}
                  aria-expanded={index === activeIndex}
                  id={`menu-${index}-button`}
                  dangerouslySetInnerHTML={toSuperscript(title)}
                ></Link>
              ) : (
                <Link
                  getProps={isPartiallyActive}
                  {...linkConfig}
                  to={path}
                  dangerouslySetInnerHTML={toSuperscript(title)}
                ></Link>
              )}

              {treeChildren.length > 0 && (
                <DropDown
                  index={index}
                  items={treeChildren}
                  isOpen={index === activeIndex}
                />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Menu;

export const query = graphql`
  fragment MenuAllPages on SitePage {
    path
    context {
      id
      slug
      title
      treeParent {
        id
        slug
        title
      }
      treeChildren {
        id
        slug
        title
      }
      hasParent
      showPage
      type
      position
    }
  }
`;
