import React from 'react';
import { graphql } from 'gatsby';
import style from './compare.mod.scss';
import { t } from '../../helpers/language';
import { Button } from '@partials';
import Img from 'gatsby-image';
import { getCompareAttrFromProduct } from '../../helpers/productHelpers';
import toSuperscript from '../../helpers/toSuperscript';

const Compare = ({ product, compareProducts, contactPagePath, path }) => {
  const cta = {
    to: contactPagePath,
    state: {
      product: product
    },
    title: t('vortex_contact_cta')
  };

  const attributes = getCompareAttrFromProduct();

  return (
    <div className={style.compare}>
      <table className={style.compare__table}>
        <thead>
          <tr>
            <th aria-hidden="true"></th>
            <th>
              <div className={style.compare__wrap}>
                <Img fluid={product.heroImage.fluid} />
              </div>
              <h5
                className={style.compare__title}
                dangerouslySetInnerHTML={toSuperscript(product.title)}
              ></h5>
            </th>
            {compareProducts.map(({ title, heroImage }, i) => (
              <th key={`title-${i}`}>
                <div className={style.compare__wrap}>
                  <Img fluid={heroImage.fluid} />
                </div>
                <h5
                  className={style.compare__title}
                  dangerouslySetInnerHTML={toSuperscript(title)}
                ></h5>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {attributes.map(({ label, key }, i) => (
            <tr key={`attribute-${i}`}>
              <th scope="row">{label}</th>
              <td>{product[key]}</td>
              {compareProducts.map((item, ii) => (
                <td key={`attribute-${key}-${ii}`}>{item[key]}</td>
              ))}
            </tr>
          ))}

          <tr>
            <th aria-hidden="true"></th>
            <td>
              <Button
                state={cta.state}
                gaTarget={'product-enquiry-cta'}
                fullWidth={true}
                to={cta.to}
              >
                {cta.title}
              </Button>
            </td>
            {compareProducts.map((item, ii) => (
              <td key={`cta-${ii}`}>
                <Button fullWidth={true} to={item.path}>
                  {t('compare_product_cta')}
                </Button>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Compare;

export const query = graphql`
  fragment Compare on DatoCmsProduct {
    id
    title
    slug
    path
    parentLandingPage {
      slug
    }
    heroImage {
      fluid(maxWidth: 244, imgixParams: { w: "244" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    seating
    lounge
    jets
    dimensions
    capacity
    price
  }
`;
