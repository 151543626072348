import React from 'react';
import style from './textarea.mod.scss';

const Textarea = React.forwardRef(
  ({ label, name, placeholder, required }, ref) => {
    const textareaConfig = {
      id: `input--${name}`,
      name,
      ref,
      placeholder,
      required
    };

    return (
      <div className={style.textarea}>
        {label && (
          <label htmlFor={`input--${name}`}>
            {label}
            {required && <span area-label="required">*</span>}
          </label>
        )}
        <textarea {...textareaConfig} />
      </div>
    );
  }
);

export default Textarea;
