import React from 'react';
import { Rings } from 'svg-loaders-react';

const Loading = props => (
  <div style={{ textAlign: 'center' }}>
    <Rings stroke={'currentColor'} />
  </div>
);

export default Loading;
