import * as translations from '@content';

const FALLBACK = 'en-CA';
const FALLBACK_KEY = FALLBACK.replace('-', '_');

const getTranslation = (key = 'spaword_cta') => {
  let lang = `${process.env.GATSBY_LANGUAGE}`;
  let langKey = lang.replace('-', '_');

  if (translations[langKey] === undefined) {
    console.info(`translations: Language ${lang} does not exist. Key: ${key}`);
  }

  if (translations[langKey].hasOwnProperty(key)) {
    return translations[langKey][key];
  } else {
    console.info(
      `translations: Transitions for language ${lang} does not exist. Falling back to default`
    );

    if (translations[FALLBACK_KEY].hasOwnProperty(key)) {
      return translations[FALLBACK_KEY][key];
    } else {
      console.info(`translations: Transitions for ${key} does not exist.`);
      return toTitleCase(key.replace('_', ' '));
    }
  }
};

const t = getTranslation;

const toTitleCase = str =>
  str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

export { t, getTranslation, translations, toTitleCase };
