import React from 'react';

const CloseIcon = ({ toggle, isOpen }) => {
  const style = {
    width: '3.125rem',
    height: '3.125rem',
    padding: 0,
    background: 'none',
    color: 'currentColor',
    border: 0
  };

  return (
    <button onClick={toggle} style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
        <g fill="currentColor" fillRule="evenodd">
          <circle cx="22.5" cy="22.5" r="22.5" opacity=".2" />
          <path d="M31.3 17c.6-.6.6-1.3 0-1.8-.5-.5-1.3-.5-1.8 0l-6.5 6-6.5-6c-.5-.5-1.3-.5-1.8 0-.6.5-.6 1.2 0 1.7L21 23l-6.4 6c-.6.6-.6 1.3 0 1.8.5.5 1.3.5 1.8 0l6.5-6 6.5 6c.5.5 1.3.5 1.8 0 .6-.5.6-1.2 0-1.7L25 23l6.4-6z" />
        </g>
      </svg>
    </button>
  );
};

export default CloseIcon;
