import React from 'react';
import style from './button.mod.scss';
import { TransitionLink } from '../../helpers/pageTransitions';
import toSuperscript from '../../helpers/toSuperscript';

const Button = ({
  id = false,
  state,
  to = '/',
  target,
  children,
  light,
  type,
  rel,
  iconAfter,
  onClick,
  fullWidth = false,
  fullWidthMobile = false,
  gaTarget,
  inverted = false
}) => {
  const ButtonContent = () => (
    <>
      <span
        className={style.button__text}
        dangerouslySetInnerHTML={toSuperscript(children)}
      ></span>
      {iconAfter && <span className={style.button__icon}>{iconAfter}</span>}
    </>
  );

  const modifier = [
    fullWidthMobile ? style['button--full-width-mobile'] : '',
    inverted ? style['button--inverted'] : '',
    fullWidth ? style['button--full-width'] : '',
    light ? style['button--light'] : ''
  ];
  const config = {
    onClick,
    target,
    rel,
    className: [style['button'], ...modifier].join(' ')
  };

  if (gaTarget) config['data-ga-target'] = gaTarget;

  const getButtonType = () => {
    let buttonType = 'link';

    if (to.indexOf('http') > -1 || to.indexOf('tel:') > -1)
      buttonType = 'anchor';

    if (type) buttonType = 'button';

    return buttonType;
  };

  const Wrapper = props => {
    switch (getButtonType()) {
      case 'anchor':
        return (
          <a href={to} {...config}>
            <ButtonContent />
          </a>
        );
      case 'button':
        return (
          <button type={type} {...config}>
            <ButtonContent />
          </button>
        );
      case 'link':
      default:
        return (
          <TransitionLink to={to} state={state} {...config}>
            <ButtonContent />
          </TransitionLink>
        );
    }
  };

  return <Wrapper />;
};

export default Button;
