import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import style from './footer.mod.scss';
import { MenuItem } from '@elements';
import { t } from '../../helpers/language';

const Footer = () => {
  const footerMenu = [
    {
      path: '/contact-us',
      title: 'Get In Touch'
    },
    {
      path: '/privacy',
      title: 'Privacy'
    }
  ];

  return (
    <footer className={style.footer}>
      <div className={style.footer__container}>
        <div className={style.footer__wrapper}>
          <div className={style.footer__logo}>{/* <Logo /> */}</div>

          <nav className={style.footer__nav}>
            <ul>
              {footerMenu.map((item, i) => (
                <MenuItem
                  modifier={'footer'}
                  key={`footer-menu-item-${i}`}
                  {...item}
                />
              ))}
            </ul>
          </nav>

          <div className={style.footer__credit}>
            <p>
              {`© ${new Date().getFullYear()} ${t('company_name')}${'  '}${t(
                'abn'
              )}`}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
