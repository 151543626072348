import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import style from './dealers.mod.scss';

const Dealers = ({ id, dealers }) => {
  return (
    <div className={style.dealers}>
      <div className={style.dealers__container}>
        {dealers.map(dealer => {
          return (
            <div className={style.dealers__dealer}>
              {dealer.image?.fluid && (
                <Img loading={'eager'} fluid={dealer.image.fluid} />
              )}
              <h4>{dealer.city}</h4>
              <h5>{dealer.title}</h5>
              <div>
                <p>{dealer.address}</p>
              </div>
              <div>
                <p>{dealer.phone}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment Dealers on DatoCmsDealerList {
    id
    padding
    background
    dealers {
      id
      city
      address
      title
      phone
      image {
        fluid(
          maxWidth: 370
          maxHeight: 240
          imgixParams: { fit: "crop", w: "370", h: "240" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    model {
      apiKey
    }
  }
`;

export default Dealers;
