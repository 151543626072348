import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import style from './prefooter.mod.scss';
import { t } from '../../helpers/language';
import Button from '../elements/button';
import FisherLogo from '@svgs/logo.svg';
import LeftSvg from '@svgs/footer-left.svg';
import RightSvg from '@svgs/footer-right.svg';

const Prefooter = () => {
  const data = useStaticQuery(graphql`
    {
      datoCmsConfig {
        contactPage {
          path
        }
      }
    }
  `);

  return (
    <aside className={style.prefooter}>
      <div className={style.prefooter__container}>
        <div className={style.prefooter__left}>
          <LeftSvg />
        </div>
        <div className={style.prefooter__right}>
          <RightSvg />
        </div>
        <div className={style.prefooter__wrapper}>
          <div className={style.prefooter__logo} children={<FisherLogo />} />
          <h3 className={style.prefooter__lead}>{t('spaword_cta')}</h3>
          <div
            className={style.prefooter__ctas}
            children={
              <>
                <Button
                  to={data.datoCmsConfig.contactPage.path}
                  gaTarget={'vortex-cta'}
                  // inverted
                  light
                >
                  {'Contact Us'}
                </Button>
                <Button
                  // inverted
                  to={t('vortex_showroom_url')}
                  rel="noopener noreferrer"
                  target="_blank"
                  gaTarget={'vortex-showroow-cta'}
                  light
                >
                  {'Find a dealer'}
                </Button>
              </>
            }
          />
          {/* <h4 className={style.prefooter__message}>{t('exclusive_message')}</h4> */}
        </div>
      </div>
    </aside>
  );
};

export default Prefooter;
