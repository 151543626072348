import React from 'react';

const PhoneIcon = ({ number }) => {
  const style = {
    width: '3.125rem',
    height: '3.125rem',
    padding: 0,
    background: 'none',
    color: 'currentColor',
    border: 0
  };

  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g fill="currentColor" fillRule="evenodd">
        <circle cx="25" cy="25" r="25" opacity=".2" />
        <path d="M15.8 17.5c-.3-.7 0-1.5.8-1.7l3.3-1c.3 0 .6 0 .7.4l2.3 4.4c.1.3 0 .6-.2.8l-2 1.6c2.3 3.3 4.4 5.5 7.4 7.6l1.5-2c.1-.3.5-.4.7-.2L35 30c.2.1.4.4.3.7l-1 3.3c-.2.8-1 1.2-1.7.8-2-1-3.6-1.8-5.7-3.4a40.7 40.7 0 01-7.8-8 46.1 46.1 0 01-3.2-5.9z" />
      </g>
    </svg>
  );
};

export default PhoneIcon;
