import React from 'react';
import { motion } from 'framer-motion';

const MenuIcon = ({ toggle, isOpen }) => {
  const style = {
    width: '3.125rem',
    height: '3.1875rem',
    padding: 0,
    background: 'none',
    color: 'currentColor',
    border: 0,
    display: 'block',
    overflow: 'visible'
  };

  return (
    <motion.button
      style={style}
      animate={isOpen ? 'open' : 'closed'}
      onClick={toggle}
      aria-label={'Toggle Menu'}
    >
      <svg viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="25" opacity=".2" fill="currentColor" />
        <motion.rect
          width="30"
          height="3"
          rx="1.5"
          fill="currentColor"
          x="10"
          y="28"
          initial={{
            originX: '50%',
            originY: '50%'
          }}
          variants={{
            open: {
              rotate: 45,
              y: -3.5,
              x: 3
            },
            closed: {
              rotate: 0,
              y: 0,
              x: 0
            }
          }}
        />
        <motion.rect
          width="30"
          height="3"
          rx="1.5"
          fill="currentColor"
          x="10"
          y="19"
          initial={{
            originX: '50%',
            originY: '50%'
          }}
          variants={{
            open: {
              rotate: -45,
              y: 3,
              x: 3
            },
            closed: {
              rotate: 0,
              y: 0,
              x: 0
            }
          }}
        />
      </svg>
    </motion.button>
  );
};

export default MenuIcon;
