const toSuperscript = (str = '') => {
  return {
    __html:
      str !== null
        ? str.replace(/®/gi, '<sup>®</sup>').replace(/™/gi, '<sup>™</sup>')
        : ''
  };
};

export default toSuperscript;
