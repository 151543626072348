import React from 'react';
import style from './menu-item.mod.scss';
import ThemeContext from '../../context/themeContext';
import { TransitionLink } from '../../helpers/pageTransitions';
import toSuperscript from '../../helpers/toSuperscript';
import { motion } from 'framer-motion';

const MenuItem = ({
  path,
  title,
  showIcon,
  backIcon = false,
  tabIndex = 0,
  onClick = () => {},
  isButton = false,
  vertical = false,
  dataKey = false,
  modifier,
  index
}) => {
  const blockClass = [style['menu-item']];

  blockClass.push(
    vertical ? style['menu-item--vertical'] : style['menu-item--horizontal']
  );

  if (showIcon) blockClass.push(style['menu-item--has-icon']);

  if (backIcon) blockClass.push(style['menu-item--is-back']);

  if (modifier) blockClass.push(style['menu-item--' + modifier]);

  const config = {
    className: style['menu-item__link'],
    activeClassName: style['menu-item__link--current'],
    to: path,
    tabIndex,
    children: (
      <>
        {backIcon && (
          <svg
            className={style['menu-item__icon']}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 492 492"
          >
            <path
              fill="currentColor"
              d="M383 227L164 8c-5-5-12-8-19-8s-14 3-19 8l-16 16a27 27 0 000 38l183 184-184 184a27 27 0 000 38l16 16c6 5 12 8 19 8s14-3 20-8l219-219a27 27 0 000-38z"
            />
          </svg>
        )}
        <span dangerouslySetInnerHTML={toSuperscript(title)}></span>
        {showIcon && (
          <svg
            className={style['menu-item__icon']}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 492 492"
          >
            <path
              fill="currentColor"
              d="M383 227L164 8c-5-5-12-8-19-8s-14 3-19 8l-16 16a27 27 0 000 38l183 184-184 184a27 27 0 000 38l16 16c6 5 12 8 19 8s14-3 20-8l219-219a27 27 0 000-38z"
            />
          </svg>
        )}
      </>
    )
  };

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 16,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  return path ? (
    <ThemeContext.Consumer>
      {({ isSidebarOpen, closeSidebar }) => {
        return (
          <motion.li
            variants={variants}
            className={blockClass.join(' ')}
            {...dataKey}
          >
            {isButton ? (
              <button
                aria-label={config.children}
                className={config.className}
                tabIndex={config.tabIndex}
                children={config.children}
                onClick={onClick}
              />
            ) : (
              <TransitionLink {...config} onClick={onClick} />
            )}
          </motion.li>
        );
      }}
    </ThemeContext.Consumer>
  ) : null;
};

export default MenuItem;
