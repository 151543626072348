import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './card.mod.scss';
import { Button, Features } from '@partials';
import { FaCheckCircle } from 'react-icons/fa';
import { t } from '../../helpers/language';
import uniqid from 'uniqid';
import { TransitionLink } from '../../helpers/pageTransitions';
import toSuperscript from '../../helpers/toSuperscript';

const Card = ({
  heading,
  caption,
  image,
  hoverImage,
  label,
  link,
  onClick,
  links = [],
  list,
  items,
  buttonsRight = false
}) => {
  if (link) links.push(link);

  if (links.length < 1) {
    return null;
  }

  const modifiers = [
    buttonsRight ? style['card--buttons-right'] : ' ',
    links.length > 1 ? style['card--multi-buttons'] : ''
  ];

  const productCardConfig = {
    className: [style['card'], ...modifiers].join(' ')
  };

  const config = {
    onClick,
    to: links[0].path
  };

  return (
    <div {...productCardConfig}>
      {image && (
        <TransitionLink {...config}>
          <div className={style.card__image}>
            <Img fluid={image.fluid} />
            {hoverImage && (
              <div className={style.card__image__hover}>
                <Img fluid={hoverImage.fluid} />
              </div>
            )}
          </div>
        </TransitionLink>
      )}
      <div className={style.card__content}>
        {label && <h5 dangerouslySetInnerHTML={toSuperscript(label)}></h5>}
        <TransitionLink {...config}>
          {heading && (
            <h3 dangerouslySetInnerHTML={toSuperscript(heading)}></h3>
          )}
        </TransitionLink>
        {caption && (
          <p
            className={style.card__caption}
            dangerouslySetInnerHTML={toSuperscript(caption)}
          ></p>
        )}
        {list && (
          <ul className={style.card__list}>
            {list.map(item => (
              <li key={uniqid()} className={style['card__list-item']}>
                <FaCheckCircle className={style['card__list-icon']} />
                <span
                  dangerouslySetInnerHTML={toSuperscript(item.description)}
                ></span>
              </li>
            ))}
          </ul>
        )}

        {items && <Features items={items} simple />}
        <div className={style['card__cta-wrapper']}>
          {links.map(({ path, title = t('generic_cta'), ...linkProps }) => (
            <div key={uniqid()} className={style.card__cta}>
              <Button to={path} {...linkProps} fullWidthMobile={true}>
                {title}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;

export const query = graphql`
  fragment Card on DatoCmsCard {
    caption
    heading
    image {
      fluid(maxWidth: 496, imgixParams: { w: "496", h: "273", fit: "crop" }) {
        ...GatsbyDatoCmsFluid
      }
    }
    id
    label
    link {
      ... on DatoCmsProduct {
        path
        title
      }
      ... on DatoCmsPage {
        path
      }
      ... on DatoCmsCollection {
        path
        title
      }
    }
  }

  fragment CollectionCard on DatoCmsCollection {
    id
    path
    title
    image {
      fluid(
        maxWidth: 568
        imgixParams: {
          w: "568"
          h: "402"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          pad: 26
        }
      ) {
        ...GatsbyDatoCmsFluid
      }
    }
    hoverImage {
      fluid(
        maxWidth: 568
        imgixParams: {
          w: "568"
          h: "402"
          fit: "fill"
          fill: "solid"
          fillColor: "#ffffff"
          pad: 26
        }
      ) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    leadText
    salesFeatures {
      description
    }
  }

  fragment ProductCard on DatoCmsProduct {
    id
    path
    heroImage {
      fluid(
        maxWidth: 568
        imgixParams: {
          w: "568"
          h: "402"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          pad: 26
        }
      ) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    hoverImage {
      fluid(
        maxWidth: 568
        imgixParams: {
          w: "568"
          h: "402"
          fit: "fill"
          fill: "solid"
          fillColor: "#ffffff"
          pad: 26
        }
      ) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    title
    shortDescription
    displaySubtitle
    ...InfusionsoftTags
    seating
    lounge
    jets
    dimensions
    capacity
    price
  }
`;
