import React from 'react';
import style from './select.mod.scss';

const Select = React.forwardRef(
  ({ label, name, placeholder, options, ErrorMessage, required }, ref) => {
    const modifier = ErrorMessage ? style['select--error'] : '';

    const selectConfig = {
      name,
      ref,
      required
    };

    return (
      <div className={[style.select, modifier].join(' ')}>
        {label && (
          <label htmlFor={`select--${name}`}>
            {label}
            {required && <span aria-label="required">*</span>}
          </label>
        )}
        <select id={`select--${name}`} {...selectConfig}>
          {placeholder && <option value="">{placeholder}</option>}
          {options.map(({ value, text }) => (
            <option
              aria-label={text}
              key={`select--${name}--${value}`}
              children={text}
              value={value}
            />
          ))}
        </select>
        {ErrorMessage && (
          <div className={style.select__error}>
            <ErrorMessage />
          </div>
        )}
      </div>
    );
  }
);

export default Select;
