import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './specs.mod.scss';
import { motion } from 'framer-motion';
import { t } from '../../helpers/language';
import Button from '../elements/button';
import { TransitionLink } from '../../helpers/pageTransitions';
import {
  FaSpa,
  FaPencilRuler,
  FaHotTub,
  FaLightbulb,
  FaWater,
  FaPlug,
  FaEnvira
} from 'react-icons/fa';
import toSuperscript from '../../helpers/toSuperscript';

const Specs = ({
  specificationsList,
  specificationsImage,
  specificationDocument,
  warrantyDocument,
  ...product
}) => {
  const specGroups = [
    ...new Set(specificationsList.map(spec => spec.feature.specificationGroup))
  ];

  const checkAPI = (value, key) => {
    if (value) {
      return value;
    } else if (product.hasOwnProperty(key) && product[key]) {
      return product[key];
    } else {
      return 'n/a';
    }
  };
  const SpecGroup = ({ i, groupName, specsInGroup }) => {
    const [expanded, setExpanded] = useState(i === 0 ? true : false);
    const isOpen = expanded;

    const Icon = () => {
      const config = { className: style.specs__icon };
      switch (groupName) {
        case 'Fisher Experience':
          return <FaSpa {...config} />;
        case 'Design & Build Quality':
          return <FaPencilRuler {...config} />;
        case 'Hydrotherapy':
          return <FaHotTub {...config} />;
        case 'Lighting':
          return <FaLightbulb {...config} />;
        case 'Water Care':
          return <FaWater {...config} />;
        case 'Power':
          return <FaPlug {...config} />;
        case 'Energy Efficiency':
          return <FaEnvira {...config} />;
        default:
          return <FaSpa {...config} />;
      }
    };
    return (
      <div className={style[`specs__group`]}>
        <a
          href={`#specs-group-${i}`}
          onClick={e => {
            e.preventDefault();
            setExpanded(isOpen ? false : true);
          }}
        >
          <h4 className={style['specs__group-header']}>
            <span className={style['specs__iconwrap']}>
              <Icon />
            </span>
            {groupName}
            <motion.span
              animate={isOpen ? 'open' : 'collapsed'}
              variants={{
                open: { rotate: '180deg' },
                collapsed: { rotate: '0' }
              }}
              className={style.specs__arrow}
            ></motion.span>
          </h4>
        </a>

        <motion.div
          layoutTransition
          useInvertedScale
          className={style.specs__items}
          key={`specs-group-${i}`}
          id={`specs-group-${i}`}
          animate={isOpen ? 'open' : 'collapsed'}
          initial={'collapsed'}
          variants={{
            open: { height: '100%', opacity: 1 },
            collapsed: { height: 0, opacity: 0 }
          }}
        >
          {specsInGroup.map(({ id, value, feature, customDescriptionNode }) => (
            <dl key={id}>
              <dt>
                <p dangerouslySetInnerHTML={toSuperscript(feature.title)}></p>
              </dt>
              <dd
                dangerouslySetInnerHTML={toSuperscript(
                  checkAPI(value, feature.key)
                )}
              ></dd>
              <dd>
                <span
                  dangerouslySetInnerHTML={toSuperscript(
                    customDescriptionNode?.childMarkdownRemark?.html ||
                      feature.defaultDescription
                  )}
                />
                {feature.link && (
                  <TransitionLink to={feature.link.path}>
                    {t('specs_cta')}
                  </TransitionLink>
                )}
              </dd>
            </dl>
          ))}
        </motion.div>
      </div>
    );
  };

  return (
    <div className={style.specs}>
      <figure className={style.specs__diagram}>
        {specificationsImage && <Img fluid={specificationsImage.fluid} />}
        <figcaption>
          {product.flipProductDimensions === null ? (
            <>
              <span className={style.specs__length}>{product.length} m</span>
              <span className={style.specs__width}>{product.width} m</span>
            </>
          ) : (
            <>
              <span className={style.specs__length}>{product.width} m</span>
              <span className={style.specs__width}>{product.length} m</span>
            </>
          )}
        </figcaption>
      </figure>

      <div className={style.specs__details}>
        {specGroups.map((groupName, i) => {
          const specsInGroup = specificationsList.filter(
            spec => groupName === spec.feature.specificationGroup
          );
          return (
            <SpecGroup
              key={`specs-group-${groupName}`}
              i={i}
              groupName={groupName}
              specsInGroup={specsInGroup}
              className={style.specs__group}
            />
          );
        })}
        <div className={style.specs__buttons}>
          {specificationDocument && (
            <Button
              to={specificationDocument.url}
              target="_blank"
              fullWidth={true}
            >
              {t('specs_download_button')}
            </Button>
          )}
          {warrantyDocument && (
            <Button to={warrantyDocument.url} target="_blank" fullWidth={true}>
              {t('warranty_download_button')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Specs;

export const query = graphql`
  fragment Specs on DatoCmsProduct {
    length
    width
    flipProductDimensions
    specificationsDescription
    specificationsImage {
      fluid(
        maxHeight: 490
        maxWidth: 490
        imgixParams: { h: "490", w: "490" }
      ) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }

    specificationDocument {
      url
    }
    warrantyDocument {
      url
    }

    specificationsList {
      id
      value
      customDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      feature {
        title
        specificationGroup
        key
        defaultDescription
        link {
          ... on DatoCmsPage {
            path
          }
        }
      }
    }
  }
`;
