import React from 'react';
import style from './drop-down.mod.scss';
import MenuItem from './menu-item';
import { motion } from 'framer-motion';

const DropDown = ({ isOpen, items, index }) => {
  const listConfig = {
    className: `${style['drop-down']} ${
      isOpen ? style['drop-down--open'] : style['drop-down--closed']
    }`,
    'aria-labelledby': `menu-${index}-button`,
    id: `menu-${index}-box`
  };

  const variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      opacity: 0,
      y: 32,
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  return (
    <div {...listConfig}>
      <motion.ul
        initial={'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={variants}
        className={style['drop-down__list']}
      >
        {items.map(({ path, title, parentLandingPage }, index) => (
          <MenuItem
            index={index}
            vertical={true}
            key={`drop-down__item--${parentLandingPage}-${index}-`}
            title={title}
            path={path}
            tabIndex={isOpen ? 0 : -1}
          />
        ))}
      </motion.ul>
    </div>
  );
};

export default DropDown;
