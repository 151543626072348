import React from 'react';
import zeroFill from 'zero-fill';
import style from './progress.mod.scss';

const Progress = ({ position, length, context = 'Step' }) => (
  <div className={style.progress}>
    <span
      className={style.progress__label}
      children={`${context} ${position} of ${length}`}
    />
    <span
      className={style.progress__display}
      aria-hidden="true"
      children={`${zeroFill(2, position)} / ${zeroFill(2, length)}`}
    />
  </div>
);

export default Progress;
