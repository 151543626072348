import Typography from 'typography';

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.666,
  scaleRatio: 2.375,
  headerFontFamily: ['Nunito', 'Arial', 'sans-serif'],
  // headerWeight: 500,
  bodyFontFamily: ['Nunito', 'Arial', 'sans-serif'],
  bodyWeight: 'normal'
});

// Export helper functions
export const { scale, rhythm, options } = typography;
export default typography;
