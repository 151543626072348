import React from 'react';
import { graphql } from 'gatsby';
import style from './cta.mod.scss';
import { Button } from '@partials';
import { t } from '../../helpers/language';
import toSuperscript from '../../helpers/toSuperscript';

const Cta = ({
  heading,
  icon,
  caption,
  externalLink,
  internalLink,
  linkText = t('generic_cta')
}) => (
  <div className={style.cta}>
    <div className={style.cta__icon}>
      <img src={icon.url} alt={icon.alt} />
    </div>
    <div className={style.cta__body}>
      <h2 dangerouslySetInnerHTML={toSuperscript(heading)}></h2>
      <p dangerouslySetInnerHTML={toSuperscript(caption)}></p>
      {internalLink ? (
        <Button to={internalLink.path}>{linkText}</Button>
      ) : (
        <Button to={externalLink}>{linkText}</Button>
      )}
    </div>
  </div>
);

export default Cta;

export const query = graphql`
  fragment Cta on DatoCmsIconCard {
    model {
      apiKey
    }
    heading
    caption
    icon {
      url
      alt
    }
    linkText
    padding
    background
    externalLink
    internalLink {
      ... on DatoCmsPage {
        path
      }
    }
  }
`;
