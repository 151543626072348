import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Swatch } from '@partials';
import style from './swatches.mod.scss';
import { t } from '../../helpers/language';
// import { motion, AnimatePresence } from 'framer-motion';

const Swatches = ({ image, shellColours, cabinetColours }) => {
  const showShells = shellColours.length > 0;
  const showCabinets = cabinetColours.length > 0;

  return (
    <div className={style.swatches}>
      {image && (
        <div className={style.swatches__render}>
          <div className={style['swatches__render-image']}>
            <Img objectFit="contain" role="presentation" fluid={image.fluid} />
          </div>
        </div>
      )}

      <div className={style.swatches__wrapper}>
        {showShells && (
          <Fragment>
            <h3 className={style.swatches__heading}>
              {t('colour_shell_heading')}
            </h3>

            <div className={style.swatches__list}>
              {shellColours.map(item => (
                <div key={`shell-${item.id}`} className={style.swatches__item}>
                  <Swatch {...item} />
                </div>
              ))}
            </div>
          </Fragment>
        )}

        {showCabinets && (
          <Fragment>
            <h3 className={style.swatches__heading}>
              {t('colour_cabinet_heading')}
            </h3>
            <div className={style.swatches__list}>
              {cabinetColours.map(item => (
                <div key={`shell-${item.id}`} className={style.swatches__item}>
                  <Swatch key={`cabinet-${item.id}`} {...item} />
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Swatches;

export const query = graphql`
  fragment Swatches on DatoCmsProduct {
    shellColours {
      id
      name
      model {
        apiKey
      }
      swatch {
        fixed(
          height: 102
          width: 168
          imgixParams: { fit: "crop", w: "168", h: "102" }
        ) {
          ...GatsbyDatoCmsFixed
        }
      }
    }

    cabinetColours {
      id
      name
      model {
        apiKey
      }
      swatch {
        fixed(
          height: 102
          width: 168
          imgixParams: { fit: "crop", w: "168", h: "102" }
        ) {
          ...GatsbyDatoCmsFixed
        }
      }
    }
  }
`;
